import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { endOfYear, format, startOfYear, subDays, subYears } from 'date-fns'
import { flow } from 'lodash/fp'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { parseDateISOStringInLocalTimezone } from 'shared'
import { AppState } from 'store'
import { useRdot360Context } from '../../store/rdot360Context'
import { IActivityDurationOptions } from './ActivityDetailView'

interface IDateRange {
  startDate?: string
  endDate?: string
}

const currentDate = new Date()
const formatDate = (date: Date) => format(date, 'yyyy-MM-dd')

const { actions, reducer } = createSlice({
  name: 'activityDetailsUiState',
  initialState: {
    dateSelection: 'Last 10 Days',
    selectedDateRange: {
      startDate: formatDate(subDays(currentDate, 9)),
      endDate: formatDate(currentDate)
    },
    searchText: '',
    isDownloading: false
  } as IActivityDetailUiState,
  reducers: {
    setStartDate: (state, action: PayloadAction<string | undefined>) => {
      state.startDate = action.payload
    },
    setEndDate: (state, action: PayloadAction<string | undefined>) => {
      state.endDate = action.payload
    },
    setDateSelection: (
      state,
      action: PayloadAction<IActivityDurationOptions>
    ) => {
      state.dateSelection = action.payload
    },
    setCategories: (state, action: PayloadAction<string[] | undefined>) => {
      state.categories = action.payload
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload
    },
    setIsDownloading: (state, action: PayloadAction<boolean | undefined>) => {
      state.isDownloading = action.payload
    },
    setSelectedDateRange: (
      state,
      action: PayloadAction<IDateRange | undefined>
    ) => {
      state.selectedDateRange = action.payload
    }
  }
})

export { reducer as activityDetailUiStateReducer }

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.modules.activity.activityDetailUiState

const getStartDate = flow(rootSelector, (x) => x.startDate)
const getEndDate = flow(rootSelector, (x) => x.endDate)
const getDateSelection = flow(rootSelector, (x) => x.dateSelection)
const getCategories = flow(rootSelector, (x) => x.categories)
const getSearchText = flow(rootSelector, (x) => x.searchText)
const getIsDownloading = flow(rootSelector, (x) => x.isDownloading)
const getSelectedDateRange = flow(rootSelector, (x) => x.selectedDateRange)
export interface IActivityDetailUiState {
  startDate?: string
  endDate?: string
  dateSelection: IActivityDurationOptions
  categories?: string[]
  searchText: string
  dateRangeString?: string
  isDownloading?: boolean
  selectedDateRange?: IDateRange
}

const selectDateRangeString = createSelector(
  [getSelectedDateRange],
  (dateRange) => {
    if (!dateRange) {
      return undefined
    }
    const { startDate, endDate } = dateRange
    if (!startDate || !endDate) {
      return undefined
    }
    return `FromTo,${startDate},${endDate}`
  }
)

export const useActivityDetailUiState = () => {
  const { asOfDate: asOfDateString } = useRdot360Context()
  const asOfDate = useMemo(
    () =>
      asOfDateString
        ? parseDateISOStringInLocalTimezone(asOfDateString)
        : new Date(),
    [asOfDateString]
  )
  const dispatch = useDispatch()

  const setStartDate = useCallback(
    (startDate?: string) => {
      dispatch(actions.setStartDate(startDate))
    },
    [dispatch]
  )

  const setEndDate = useCallback(
    (endDate?: string) => {
      dispatch(actions.setEndDate(endDate))
    },
    [dispatch]
  )

  const setSelectedDateRange = useCallback(
    (dateRange?: IDateRange) => {
      dispatch(actions.setSelectedDateRange(dateRange))
    },
    [dispatch]
  )

  const setDateSelection = useCallback(
    (selection?: IActivityDurationOptions) => {
      if (!selection) {
        return
      }
      dispatch(actions.setDateSelection(selection))
      switch (selection) {
        case 'Today':
          setSelectedDateRange({
            startDate: formatDate(asOfDate),
            endDate: formatDate(asOfDate)
          })
          return

        case 'Prior Day':
          setSelectedDateRange({
            startDate: formatDate(subDays(asOfDate, 1)),
            endDate: formatDate(subDays(asOfDate, 1))
          })
          return

        case 'Last 10 Days':
          setSelectedDateRange({
            startDate: formatDate(subDays(asOfDate, 9)),
            endDate: formatDate(asOfDate)
          })
          return

        case 'Last 30 Days':
          setSelectedDateRange({
            startDate: formatDate(subDays(asOfDate, 29)),
            endDate: formatDate(asOfDate)
          })
          return

        case 'Previous Year':
          setSelectedDateRange({
            startDate: formatDate(startOfYear(subYears(asOfDate, 1))),
            endDate: formatDate(endOfYear(subYears(asOfDate, 1)))
          })
          return

        case 'Current Year':
          setSelectedDateRange({
            startDate: formatDate(startOfYear(asOfDate)),
            endDate: formatDate(asOfDate)
          })
          return
      }
    },
    [asOfDate, dispatch, setSelectedDateRange]
  )

  const setCategories = useCallback(
    (categories?: string[]) => {
      dispatch(actions.setCategories(categories))
    },
    [dispatch]
  )

  const startDate = useSelector(getStartDate)

  const endDate = useSelector(getEndDate)

  const dateSelection = useSelector(getDateSelection)

  const categories = useSelector(getCategories)

  const setCustomRange = useCallback(
    (startDate?: string, endDate?: string) => {
      if (!startDate || !endDate) {
        return
      }
      dispatch(
        actions.setSelectedDateRange({
          startDate,
          endDate
        })
      )
    },
    [dispatch]
  )

  const dateRangeString = useSelector(selectDateRangeString)

  const setIsDownloading = useCallback(
    (value?: boolean) => {
      dispatch(actions.setIsDownloading(value))
    },
    [dispatch]
  )

  const setRangeForDay = useCallback(
    async (date: string) => {
      setDateSelection('Custom Range')
      setStartDate(date)
      setEndDate(date)
      setCustomRange(date, date)
    },
    [setCustomRange, setDateSelection, setEndDate, setStartDate]
  )

  const searchText = useSelector(getSearchText)
  const isDownloading = useSelector(getIsDownloading)
  const setSearchText = useCallback(
    (search?: string) => {
      dispatch(actions.setSearchText(search || ''))
    },
    [dispatch]
  )
  const selectedDateRange = useSelector(getSelectedDateRange)
  return {
    setStartDate,
    setEndDate,
    setDateSelection,
    setCategories,
    startDate,
    endDate,
    dateSelection,
    categories,
    setCustomRange,
    dateRangeString,
    searchText,
    setSearchText,
    setRangeForDay,
    isDownloading,
    setIsDownloading,
    selectedDateRange
  }
}
