import { IDropdownOption } from '@fluentui/react'

interface IAddress {
  name: string
  key: string
  addrLine1?: IFieldDetails
  addrLine2?: IFieldDetails
  addrLine3?: IFieldDetails
  addrLine4?: IFieldDetails
  addrLine5?: IFieldDetails
  addrLine6_city?: IFieldDetails
  addrLine7_stateProvince?: IFieldDetails
  addrLine8_zip1?: IFieldDetails
  addrLine8_zip2?: IFieldDetails
  addrLine9_country?: IFieldDetails
}

interface IFieldDetails {
  label: string
  isList?: boolean
}

export const AddrFieldsByFormat: {
  [key: string]: IAddress
} = {
  Domestic: {
    name: 'Domestic',
    key: 'D',
    addrLine1: {
      label: 'Name Line 1'
    },
    addrLine2: {
      label: 'Name Line 2'
    },
    addrLine3: {
      label: 'Attention/Care of'
    },
    addrLine4: {
      label: 'Address Line 1'
    },
    addrLine5: {
      label: 'Address Line 2'
    },
    addrLine6_city: {
      label: 'City',
      isList: false
    },
    addrLine7_stateProvince: {
      label: 'State',
      isList: true
    },
    addrLine8_zip1: {
      label: 'ZIP'
    },
    addrLine8_zip2: {
      label: ''
    }
  },
  Military: {
    name: 'Military',
    key: 'M',
    addrLine1: {
      label: 'Name Line 1'
    },
    addrLine2: {
      label: 'Name Line 2'
    },
    addrLine3: {
      label: 'Attention/Care of'
    },
    addrLine4: {
      label: 'Address Line 1'
    },
    addrLine5: {
      label: 'Address Line 2'
    },
    addrLine6_city: {
      label: 'City',
      isList: true
    },
    addrLine7_stateProvince: {
      label: 'State',
      isList: true
    },
    addrLine8_zip1: {
      label: 'ZIP'
    },
    addrLine8_zip2: {
      label: ''
    }
  },
  Foreign: {
    name: 'Foreign',
    key: 'F',
    addrLine1: {
      label: 'Name Line 1'
    },
    addrLine2: {
      label: 'Name Line 2'
    },
    addrLine3: {
      label: 'Attention/Care of'
    },
    addrLine4: {
      label: 'Address Line 1'
    },
    addrLine5: {
      label: 'Address Line 2'
    },
    addrLine6_city: {
      label: 'City',
      isList: false
    },
    addrLine7_stateProvince: {
      label: 'Province',
      isList: false
    },
    addrLine8_zip1: {
      label: 'Postal Code'
    },
    addrLine9_country: {
      label: 'Country'
    }
  },
  NonStandard: {
    name: 'Non Standard',
    key: 'N',
    addrLine1: {
      label: 'Address Line 1'
    },
    addrLine2: {
      label: 'Address Line 2'
    },
    addrLine3: {
      label: 'Address Line 3'
    },
    addrLine4: {
      label: 'Address Line 4'
    },
    addrLine5: {
      label: 'Address Line 5'
    },
    addrLine6_city: {
      label: 'Address Line 6',
      isList: false
    },
    addrLine7_stateProvince: {
      label: 'State',
      isList: true
    }
  }
}

export const AddressFormatOptions: IDropdownOption[] = [
  {
    key: AddrFieldsByFormat.Domestic.key,
    text: AddrFieldsByFormat.Domestic.name
  },
  {
    key: AddrFieldsByFormat.Military.key,
    text: AddrFieldsByFormat.Military.name
  },
  {
    key: AddrFieldsByFormat.Foreign.key,
    text: AddrFieldsByFormat.Foreign.name
  },
  {
    key: AddrFieldsByFormat.NonStandard.key,
    text: AddrFieldsByFormat.NonStandard.name
  }
]

export const ChargeHomeOffice = {
  Y: 'Yes',
  N: 'No'
}
